export const API_BASE_URL = `https://my-json-server.typicode.com/Itsolution-git/portfoilo-data/`


export const MAIN = {
  "basic":{
    "firstName":"Dimitry",
    "lastName":"Azralsky",
    "email":"dazralsky@gmail.com",
    "logo":"https://i.ibb.co/mDqB0ys/49536502-biceps-flex-arm-vector-icon.jpg",
    "facebook":"",
    "linkedin":"",
    "twitter":"",
    "instagram":"",
    "github":"https://github.com/dazralsky",
    "calendlyLink": "https://calendly.com/dazralsky/30min"
  },
  "homepage":{
    "top":{
      "title":"Senior Software Engineer",
      "backImage":"https://i.ibb.co/bLkWBK2/Dazralsky.jpg"
    },
    "aboutMe":{
      "image":"https://i.ibb.co/bLkWBK2/Dazralsky.jpg",
      "desc":"I am an industry-specific technology expert with decade of combined experience delivering interoperable, scalable, and flexible web & mobile applications for all kinds of industries.",
      "skills":[
        {
          "name":"Expertise",
          "details":[
            {
              "title":"1. Back-end",
              "org":"",
              "subtitle":"",
              "desc":["Ruby/Ruby on Rails/Sinatra/Spree/Solidus/Grape", "NodeJS/ExpressJS/HapiJS/SailsJS/NestJS", "Python/Django"]
            },
            {
              "title":"2. Front-end",
              "org":"",
              "subtitle":"",
              "desc":["Javascript/Coffeescript/es6/Typescript", "ReactJS/Next JS/GatsbyJS(SSR)", "Angular1.x/Angular2+", "VueJS/NuxtJS/Vuetify"]
            },
            {
              "title":"3. Storage",
              "org":"",
              "subtitle":"",
              "desc":["PostgreSQL/MySQL/MSSQL", "MongoDB/DynamoDB/CouchDB/Cassandra", "Elasticsearch/Algoliasearch", "Redis/Memcache"]
            },
            {
              "title":"4. Test",
              "org":"",
              "subtitle":"",
              "desc":["Rspec/Selenium/Capybara/Cucumber", "Mocha/Chai/Jasmine/Jest/Karma", "Cypress/Solidity/Zapier/Work automation"]
            },
            {
              "title":"5. DevOps",
              "org":"",
              "subtitle":"",
              "desc":["AWS/Microsoft Azure/Google Cloud/Oracle/Digital Ocean/Heroku", "Docker/Kubernetes"]
            }
          ]
        },
        {
          "name":"Careers",
          "details":[
            {
              "title":"Freelancing",
              "org":"Online",
              "subtitle":"Nov 2021 - Present",
              "desc":"Working as an independent freelancer with Ruby on Rails and many variants of Javascript technologies."
            },
            {
              "title":"Project Manager",
              "org":"Nokia-CloudBand",
              "subtitle":"May 2016 - Nov 2021",
              "desc":"Nokia's CloudBand software service offers a secure, open-source-based virtual infrastructure for managing compute, storage, and network resources, ensuring robustness, security, and simplicity in handling OpenStack virtual machines. It automates virtual Network Function (VNF) lifecycle management and cloud resource management, featuring standardized APIs for compatibility across vendors and offering pre-integrated solutions for Nokia's •NF portfolio."
            },
            {
              "title":"Principal Software Engineer",
              "org":"Amdocs",
              "subtitle":"Apr 2012 - May 2016",
              "desc":"Amdocs provides solutions for billing, revenue management, and customer care, streamlining operations and enhancing customer experience in the telecom sector. It aids businesses in their digital transformation journey by offering innovative solutions for customer experience, service orchestration, and network functions virtualization(NFV)."
            }
          ]
        },
        {
          "name":"Education",
          "details":[
            {
              "title":"Associate's degree",
              "org":"College of IAF, Tel Aviv",
              "subtitle":"Oct 2007 - May 2009",
              "desc":"Computer Science and Philosophy Double Major with a minor in Science."
            },
          ]
        },
      ]
    },
    "services":{
      "desc":"",
    },
    "projects":{
      "desc":"Representative apps I have built through last 10 years are described below",
    },
    "news":[

    ],
    "skills": [
       {
          "label": "Languages",
          "details": "JavaScript, Typescript, SQL, HTML, CSS, Python",
       },
       {
          "label": "Frameworks",
          "details": "NestJS, Express, Loopback, Cypress, React, Vue",
       },
       {
          "label": "Storage",
          "details": "MySQL, Firebase, Redis, PostgreSQL, Elasticsearch, MongoDB, DynamoDB",
       },
       {
          "label": "DevOps",
          "details": "Docker, Kubernetes, AWS, Azure, GCP",
       },
       {
          "label": "Other",
          "details": "Cryptocurrency, Solidity, Zapier, Work automation",
       }
    ],
    "preferredEnv": {
       "desc": "Visual Studio, Sublime, Ubuntu 20.0, Bash"
    },
    "skillByYear": [
       {
          "label": "Javascript",
          "years": 10
       },
       {
          "label": "NodeJS",
          "years": 8,
       },
       {
          "label": "VueJS",
          "years": 5,
       },
       {
          "label": "React",
          "years": 6,
       },
       {
          "label": "React Native",
          "years": 5,
       }
    ],
    "contactUs":false
  }
}

export const NEWS = []
export const SERVICES = [
  {
    "id":"enterprise-software",
    "icon":"layers",
    "title":"Enterprise Software Development",
    "desc":"Full spectrum of application design, development, maintenance and support services along with flexibility of engagement models and project scopes.",
    "refLink": "",
    "back": "https://i.ibb.co/gMNZjVz/enterprise-software-development-cover.jpg",
    "pContent": [
      // "Whether you are looking for a qualified software development provider to deliver a custom enterprise software solution, an experienced integrator to create a connected software ecosystem or a diversified team of developers to handle a bunch of enterprise apps, you can turn to Iflexion.",
    ],
    "ulContent": [
      "Custom Enterprise Software Development - I help you design and implement custom enterprise apps that streamline versatile organizational and client-centric workflows. I create software solutions from scratch or tune the up and running systems to smoothly embed them into your IT landscape.",
      "Enterprise Application Integration - I help you put together scattered enterprise applications to achieve business process continuity, data integrity and barrier-free collaboration. By integrating enterprise solutions, I ensure that the entire software environment is flawless and secure.",
      "Legacy App Upgrade and Modernization - I help you revitalize your legacy software by reviewing its technical architecture, migrating it to newer platforms, enriching it with fresh features, making it device-agnostic, improving its UI and UX, as well as aligning it with modern security requirements."
    ],
    "active": true
  },
  {
    "id":"web-app",
    "icon":"monitor",
    "title":"Web Application Development",
    "desc":"High-performing, intuitive and secure web solutions that support business processes and serve users globally.",
    "refLink": "",
    "back": "https://i.ibb.co/mqt425R/custom-web-application-development-cover.jpg",
    "pContent": [
      // "Build scalable and high performance applications for the cloud using cloud-native or agnostic architectures and contemporary UI technologies.",
      // "Our approach to web product development includes consideration of scale and performance at design time. We start by wire-framing to identify requirements in good detail. Data requirements and other considerations are identified upfront to ensure a reasonable timeline and action plan.",
      // "Leverage our experience in dealing with the cloud. We consult on the product requirements to identify the right technology and architecture for the product. Our familiarity with cloud providers enables us to suggest cloud-agnostic or cloud-native architectures for the project depending on requirements.",
      // "We have experience across the cloud landscape, be it AWS, Azure or Google Cloud. We have built applications that can be moved across any of these providers (cloud-agnostic) or can suggest cloud native architectures that will save time and money during development (such as lambda on AWS).",
    ],
    "ulContent": [
      "Large-Scale Distributed Systems - I leverage modern tools, best practices, and the know-how of technology giants to design, launch, and manage flawless, flexible web applications. Our skillsets range from in-demand social networks and content delivery platforms to complex AR/VR and AI-powered solutions.",
      "Microservices Architectures - U have gone through all stages of the web app development evolution—from multi-tier architecture through SOA, to become the advocates of microservices—the granular software development approach that keeps architectures from crumbling.",
      "Real-Time Big Data Processing - I help organizations succeed in their big data journeys by guiding them from a strategy setup to the implementation of data-driven web solutions. I cover all facets of data processing, from designing data workflows to implementing micro-batching and stream processing.",
      "Cloud-Native Applications - Going cloud-native with me, you take full advantage of container-based development to build multiservice web apps adapted to elastic architectures. I design cloud-native web apps from the ground up as well as helping you migrate enterprise systems to a new cloud environment."
    ],
    "active": true
  },
  {
    "id":"api-integration",
    "icon":"cast",
    "title":"Api Development And Integration",
    "desc":"Highly secure and adaptable server-side APIs to bring along all the benefits for building robust web applications.",
    "refLink": "",
    "back": "https://i.ibb.co/SNR2zt1/api-security.jpg",
    "pContent": [
      // "Professional experience in building pipelines using jenkins.",
      // "Github, gitlab, bitbucket API hooks and various plugins in jenkins.",
      // "Docker and kubernetes, terraform.",
    ],
    "ulContent": [
      "Planning and Initial Design - This phase focuses on what the API will look like. What will the routes consist of, what are the general payload requirements, how will objects be returned, which HTTP verbs should be used—all of these are critical elements to building a solid, versionable API.",
      "Implementation and Testing - This phase is focused on implementing and deploying the API and testing against the decisions made in the planning phase. This can also include iterative changes to the API planning decisions, depending on the specific development methodology being used.",
      "Deploy and Run - This phase is focused on establishing the production environment for the API and making it available for general use. This can happen concurrently with the implementation and testing phase, with as much overlap as needed to ensure development agility.",
      "Versioning and Retirements - Once the API has shipped, you will need a strategy to manage improvements and rewrites of the API, making sure that your developers don’t have to make significant changes with each new code change. With proper versioning and plans for retirement, you can ensure your API is always in the best possible shape."
    ],
    "active": true
  }
]

export const PROJECTS = [
  {
    "id":"fountain",
    "tag":"Ruby on Rails + ReactJS",
    "title": "High Volume Hiring for the Global Workforce",
    "landing": "https://i.ibb.co/rd3Db6V/fountain-1.png",
    "images":[
      "https://i.ibb.co/BrdgTzr/fountain-2.png",
      "https://i.ibb.co/yB6XpCK/fountain-3.png",
      "https://i.ibb.co/Zd0M1g9/fountain-4.png",
    ],
    "desc": [
      "Multi-tenant based enterprise app composed of many micro-services",
      "Master/Slave db setup with PostgreSQL",
      "Elasticsearch integration",
      "Stripe/Chargebee Payment Gateway integration",
      "GoodHire/HireRight Integration",
      "Advanced/optimized tech for background process management using enterprise sidekiq",
      "Various 3rd party job boards integration",
    ],
    "link": "https://www.fountain.com",
    "techStack": [
       "Ruby on Rails", "ReactJS", "NodeJS", "PostgreSQL", "MaterialUI", "Elasticsearch"
    ],
    "utilities": [
    ],
    "active": true
  },
  {
    "id":"halontax",
    "tag":"Ruby on Rails + ReactJS",
    "title": "Tax & Bookkeeping Services",
    "landing": "https://i.ibb.co/VS8NM5Y/halontax-1.png",
    "images":[
      "https://i.ibb.co/6bKh3b6/halontax-2.png",
      "https://i.ibb.co/DY2tsdb/halontax-3.png",
      "https://i.ibb.co/4sGpWZg/halontax-4.png",
    ],
    "desc": [
      "Multi-tenant based app with dedicated micro-services for different types of tax form",
      "Using resourceful payment gateways including stripe, braintree, amazonpay, plaid, etc",
      "Accurate Background check/Sterling/Recruitics integration",
      "HelloSign/Docusign Integration",
      "Number of cron jobs to manage the different customer requests",
      "Integrated with 3rd party tax form services to accodomate the demands for different businesses",
    ],
    "link": "https://www.halontax.com",
    "techStack": [
       "Ruby on Rails", "ReactJS", "PostgreSQL", "Scala", "Redis", "Sidekiq"
    ],
    "utilities": [
    ],
    "active": true
  },
  {
    "id":"dstld",
    "tag":"Spree/Solidus + Angular1.6",
    "title": "E-commerce Platform",
    "landing": "https://i.ibb.co/cxyrVSy/dstld-4.png",
    "images":[
      "https://i.ibb.co/C681VKs/dstld-2.png",
      "https://i.ibb.co/0rTT0tc/dstld-3.png",
      "https://i.ibb.co/cxyrVSy/dstld-4.png",
    ],
    "desc": [
      "Spree/Solidus based e-commerce platform",
      "Full customization over orders, shipments, products, promotions",
      "Integrated with 3rd party shipment services like Shippo and FreightPOP",
      "Google Analytics/Adwords/Tag Manager Integration",
      "Push notification using Onesignal",
    ],
    "link": "https://www.dstld.com",
    "techStack": [
       "Ruby on Rails", "Spree", "Solidus", "AngularJS", "PostgreSQL", "Elasticsearch"
    ],
    "utilities": [
    ],
    "active": true
  },
  {
    "id": "milanote",
    "tag":"ExpressJS + ReactJS",
    "title": "Easy-to-use tool to organize the projects into visual boards.",
    "landing": "https://i.ibb.co/74dHtTf/milanote-1.png",
    "images": [
       "https://i.ibb.co/HhbHvdy/milanote-2.png",
       "https://i.ibb.co/QNrST2w/milanote-3.png",
       "https://i.ibb.co/5KyCvpq/milanote-4.png",
       "https://i.ibb.co/f1KNfDh/milanote-5.png",
    ],
    "desc": [
      "Typescript based RESTful api server built on express.",
      "TikTok API integration.",
      "Drag & Drop Form",
      "Vetty/Ubble integration",
      "Hotjar api integration",
    ],
    "techStack": [
       "ReactJS", "Redux", "Bootstrap", "MongoDB", "AWS"
    ],
    "active": true
  },
  {
    "id":"cap-business",
    "tag":"NestJS + ReactJS",
    "title": "Cap Business Plan/Decision Application",
    "landing": "https://i.ibb.co/2hg6jtD/screencapture-capimpact-2021-03-24-04-34-27.png",
    "images":[
      "https://i.ibb.co/8PDkvRH/Screenshot-from-2021-03-24-04-37-07.png",
      "https://i.ibb.co/3fjcmWS/Screenshot-from-2021-03-24-04-36-37.png",
      "https://i.ibb.co/xg9tnKG/Screenshot-from-2021-03-24-04-36-25.png",
      "https://i.ibb.co/9vDCYH3/screencapture-54-88-176-131-3000-2021-03-24-04-36-05.png",
      "https://i.ibb.co/3mFKbjT/screencapture-54-88-176-131-3001-docs-2021-03-24-04-35-52.png",
      "https://i.ibb.co/prJFLfH/screencapture-clearprism-2021-03-24-04-34-53.png",
      "https://i.ibb.co/2hg6jtD/screencapture-capimpact-2021-03-24-04-34-27.png"
    ],
    "desc": [
      "CapBusiness tool for compaines",
      "Backend built in NestJS + PostgreSQL.",
      "Frontend built in React",
    ],
    "link": "https://testautomationu.applitools.com",
    "techStack": [
       "NodeJS", "Typescript", "NestJS", "PostgreSQL", "React", "MaterialUI"
    ],
    "utilities": [
    ],
    "active": true
  },
  {
    "id":"monsido",
    "tag":"Ruby on Rails + Angular10",
    "title": "Monitoring and automation tools for a flawless website UX across Web Accessibility",
    "landing": "https://i.ibb.co/YNgdwt1/monsido-1.png",
    "images":[
      "https://i.ibb.co/cygV62z/monsido-2.png",
      "https://i.ibb.co/WGfJT42/monsido-3.png",
      "https://i.ibb.co/nrnNKbp/monsido-4.png",
    ],
    "desc": [
      "Built with ruby-grape that supports DSL for RESTful apis",
      "Database shard/replicas for dealing with hundreds of thousands of data",
      "Angular10-based front-end integrated with angular material UI",
    ],
    "link": "https://www.monsido.com",
    "techStack": [
       "Ruby on Rails", "Angular10", "PostgreSQL", "Angular Material UI"
    ],
    "utilities": [
    ],
    "active": true
  },
  {
    "id":"healthiq",
    "tag":"Ruby on Rails + BackbonJS",
    "title": "Engage with your health and earn rewards",
    "landing": "https://i.ibb.co/6JG8Tgm/healthiq-1.png",
    "images":[
      "https://i.ibb.co/J7BTM3s/healthiq-2.png",
      "https://i.ibb.co/sqyYLHN/healthiq-3.png",
      "https://i.ibb.co/hyT5z7W/healthiq-4.png",
    ],
    "desc": [
      "Built on ruby on rails + backboneJS",
      "Hippa Compliance App",
      "Insurance form management",
      "Yardstik/Zapier integration"
    ],
    "link": "https://www.healthiq.com",
    "techStack": [
       "Ruby on Rails", "BackboneJS", "MySQL"
    ],
    "utilities": [
    ],
    "active": true
  },
  {
    "id":"openmined-courses",
    "tag":"Firebase + ReactJS",
    "title": "Learn how privacy technology is changing our world and how you can lead the charge.",
    "landing": "https://i.ibb.co/ryy15z5/screencapture-courses-openmined-org-2021-03-24-04-26-09.png",
    "images":[
      "https://i.ibb.co/cF4zkJD/Screenshot-from-2021-03-24-04-29-00.png",
      "https://i.ibb.co/YhL9qy5/Screenshot-from-2021-03-24-04-28-49.png",
      "https://i.ibb.co/rGyymyZ/Screenshot-from-2021-03-24-04-28-41.png",
      "https://i.ibb.co/d4C1TKF/screencapture-courses-openmined-org-courses-2021-03-24-04-27-28.png",
      "https://i.ibb.co/1b7C3Mz/screencapture-courses-openmined-org-courses-our-privacy-opportunity-1f34d3a8-2fa1-4a1e-9ef1-eaaf9ddd.png",
      "https://i.ibb.co/ssRnBVh/screencapture-courses-openmined-org-users-dashboard-2021-03-24-04-26-43.png",
      "https://i.ibb.co/ryy15z5/screencapture-courses-openmined-org-2021-03-24-04-26-09.png"
    ],
    "desc": [
      "Learning platform offered by Openmined.org.",
      "Backend bulit in Firebase clouding functions and firestore.",
      "Frontend built in React and latest Hooks.",
      "Content management using CMS.",
      "Verifiable/Lessonly Integration",
    ],
    "link": "https://courses.openmined.org/",
    "techStack": [
       "NodeJS", "Typescript", "Firebase", "Express", "React", "ChakraUI", "TailwindCSS"
    ],
    "utilities": [
      "Nx", "CMS"
    ],
    "active": true
  },
  {
    "id": "varicent",
    "tag":"ReactJS",
    "title": "Varicent",
    "landing": "https://i.ibb.co/wzFDKs6/Screenshot-from-2021-03-24-03-16-48.png",
    "images": [
       "https://i.ibb.co/wzFDKs6/Screenshot-from-2021-03-24-03-16-48.png"
    ],
    "desc": [
       "Implemented the UI for Symon.AI, a machine learning pipeline, using React, Redux, TypeScript, and PostCSS.",
       "Owned the hiring and onboarding of new UI developers.",
       "Integrated with Intercom, Upscope, and WalkMe to provide a premium experience educating and helping users.",
       "Collaborated with product management to make decisions on what features are most valuable.",
       "Led a team of five software developers to implement the UI for all new product features.",
    ],
    "techStack": [
       "TypeScript", "RxJS", "Redux", "React", "Webpack", "Web UI", "ECharts", "PostCSS"
    ],
    "active": true
  },
  {
    "id": "justaskme",
    "tag":"ExpressJS + ReactJS",
    "title": "JustAskme gives you the opportunity to earn Every Time You answer a question. ",
    "landing": "https://i.ibb.co/CVCk5xp/justaskme-1.png",
    "images": [
       "https://i.ibb.co/CtVNSH7/justaskme-2.png",
       "https://i.ibb.co/C7tVT1q/justaskme-3.png",
       "https://i.ibb.co/LYWsYKG/justaskme-4.png",
       "https://i.ibb.co/cb8P5fz/justaskme-5.png",
    ],
    "desc": [
      "Built on MERN stack.",
      "React Material UI integration.",
      "Stripe payment gateway integration.",
    ],
    "techStack": [
       "ReactJS", "Redux", "React Material UI", "MongoDB", "Azure",
    ],
    "active": true
  },
]
